'use strict';

document.addEventListener('DOMContentLoaded', function () {
  var formPhoto = document.querySelector('[name="photoregister-photo"]'),
    formText = document.querySelector('[name="photoregister-text"]'),
    prPhoto = document.querySelector('#photoregister-photo'),
    prText = document.querySelector('#photoregister-text'),
    prNotifications = document.querySelector("#pr-notifications");

  var Notifications = {
    clear: function clear() {
      var notifications = $("#notifications");
      if (notifications) notifications.remove();
    },
    show: function show(string) {
      var element = document.createElement('div');

      formPhoto.classList.add('d-none');
      formText.classList.add('d-none');

      element.id = 'notifications';
      element.classList.add('text--center');
      element.classList.add('pr-web-notifications');
      element.innerHTML = string;
      prNotifications.appendChild(element);
    }
  };

  function switchTextCode() {
    if (!((typeof(formPhoto) == "undefined") || (formPhoto == null))) {
      formPhoto.classList.add('d-none');
      Notifications.clear();
      formText.classList.remove('d-none');
    }
  }

  function switchTakePhoto() {
    if (!((typeof(formText) == "undefined") || (formText == null))) {
      formText.classList.add('d-none');
      Notifications.clear();
      formPhoto.classList.remove('d-none');
    }
  }

  function checkMobileBrowser() {
    var isMobileBrowser = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) isMobileBrowser = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return isMobileBrowser;
  }

  if ($('#photoregister-photo').length) {
    switchTextCode();
  }

  $('.js-text-code').on('click', function () {
    switchTextCode();
  })

  $('.js-take-photo').on('click', function () {
    switchTakePhoto();
  })
  
  if (!((typeof(prPhoto) == "undefined") || (prPhoto == null))) {
    prPhoto.onchange = function () {
      var files = this.files;

      if (files && files.length > 0) {
        processPhotoregister('photo');
      }
    };
  }

  if (!((typeof(prPhoto) == "undefined") || (prPhoto == null))) {
    formPhoto.onsubmit = function (e) {
      e.preventDefault();
    };
  }

  if (!((typeof(prPhoto) == "undefined") || (prPhoto == null))) {
    formText.onsubmit = function (e) {
      e.preventDefault();
      if (prText.value === '') {
        alert('No Photoregister code submitted! Please enter a code.');
      } else {
        processPhotoregister('text');
      }
    };
  }

  function processPhotoregister(type) {
    var url = '/prc/v1/process';

    Notifications.clear();

    $('.spinner').addClass('d-flex');
    $('.spinner').removeClass('d-none');
    $('.btn-text').addClass('d-none');

    if (type === 'photo') {

      var imageForm = $("form[name='photoregister-photo']");
      var formData = new FormData(imageForm[0]);

      $.ajax({
        url: url,
        data: formData,
        type: 'POST',
        success: handlePhotoregister,
        error: handlePhotoregister,
        dataType: "json",
        contentType: false,
        processData: false,
        timeout: 0
      });

    } else if (type === 'text') {
      $.ajax({
        type: 'POST',
        url: url,
        data: { 'code': prText.value },
        success: handlePhotoregister,
        error: handlePhotoregister,
        dataType: 'json',
        timeout: 0
      });
    }
  }

  function handlePhotoregister(prResponse) {
    var cautionIcon = '<svg class="caution-icon" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#000000"><path d="M31.6 29.1l-13.9-26C17.4 2.4 16.7 2 16 2L16 2C15.3 2 14.6 2.4 14.2 3.1l-13.9 26c-0.3 0.6-0.3 1.4 0 2C0.7 31.6 1.4 32 2.1 32l27.8 0c0.7 0 1.4-0.4 1.7-1C32 30.4 32 29.7 31.6 29.1zM2.1 30L16 4l13.9 26L2.1 30zM14 14l0 6c0 1.1 0.9 2 2 2s2-0.9 2-2l0-6c0-1.1-0.9-2-2-2S14 12.9 14 14zM14 26A2 2 1080 1 0 18 26 2 2 1080 1 0 14 26z"/></svg>';
    var msg = void 0;

    Notifications.clear();

    if (prResponse.outcome === 'decode_failed' || prResponse.code == null) {
      msg = '<p class="text--primary">Sorry, we could not recognize a valid Photoregister code.</p><button class="btn btn-link-secondary btn-block js-text-code" type="button">Try again</button>';
    } else if (prResponse.outcome === 'not_found') {
      msg = '<p class="text--primary">Sorry, your code <strong>' + prResponse.code + '</strong> was not found. If this code is incorrect, please try entering your code.</p><button class="btn btn-link-primary btn-block js-text-code" type="button">Try again</button>';
    } else if (prResponse.outcome === 'error'){
      msg = '<p class="text--primary">Sorry, we were unable to process your submission.</p><button class="btn btn-link-primary btn-block js-text-code" type="button">Try again</button>';
    } else if (prResponse.outcome && !prResponse.result || prResponse.result && !prResponse.result.url) {
      msg = '<p class="text--primary">Sorry, the code <strong>' + prResponse.code + '</strong> which we received is not configured for Photoregister for Web. Please text your code to 71403.</p><a href="sms:71403;?&body=' + prResponse.code + '" class="btn btn-secondary btn-block text-white d-block d-md-none mb-8"><i class="fas fa-sms"></i> Tap to Text</a><button class="btn btn-link-primary btn-block js-text-code" type="button">Try again</button>';
    } else if (prResponse.result && prResponse.result.url) {
      return window.location = prResponse.result.url;
    } else {
      msg = '<p class="text--primary">Sorry, we were unable to process your submission.</p><button class="btn btn-secondary js-text-code" type="button">Try again</button>';
    }

    Notifications.show(cautionIcon + msg);
    $('.spinner').addClass('d-none');
    $('.spinner').removeClass('d-flex');
    $('#pr-notifications').addClass('mt-16');
    $('.btn-text').removeClass('d-none');
  }

});


$(function () {

  renderMarkdown($('#pr-web-explanation'), $.polyglot.t('pr_web_explanation', $.interpolations));

  if ( _.get($.siteConfiguration,"show_pr_web_help" ) ){
    renderMarkdown($('#pr-web-help'), $.polyglot.t('pr_web_help', $.interpolations));
  }

});

$(function () {
  if ( _.get($.siteConfiguration,"show_registration_explanation" ) ){
    renderMarkdown($('#registration-explanation'), $.polyglot.t('registration_explanation', $.interpolations));
  }

  if ( _.get($.siteConfiguration,"registration_model_field.display_help" ) ){
    renderMarkdown($('#model-help'), $.polyglot.t('model_help_text', $.interpolations));
  }

  if ( _.get($.siteConfiguration,"show_registration_disclaimer" ) ){
    renderMarkdown($('#registration-disclaimer'), $.polyglot.t('registration_disclaimer', $.interpolations));
  }
});

$(document).ready(function() {
  renderMarkdown($('#footer-text'), $.polyglot.t('footer_text', $.interpolations));
  if ( _.get($.siteConfiguration,"enable_footer_links" ) ){
    renderMarkdown($('#footer_links'), $.polyglot.t('footer_links', $.interpolations));
  }
});

$(function() {
  

  if ($(":input[name*='user[email]']").length) {
    $(":input[name*='user[email]']").rules("add", {
      regex: "^[A-Za-z0-9!%&'*+/=?^_`{|}~-]+(?:.[A-Za-z0-9!%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$".replace(new RegExp("\\.", "g"), "\\\."),
      required: $(":input[name*='user[email]']")[0].required,
      messages: {
        regex: $.polyglot.t('validations.email')
      }
    });
  }

  if ($(":input[name*='user[phone]']").length) {
    var phone_field = $(":input[name*='user[phone]']");
    var isRequired = phone_field[0].required

    if ( _.get($.siteConfiguration,"user_phone_field.display" )){
      phone_field.data("required_phone", isRequired);
      phone_field.rules('add', {
        strictPhone: isRequired
      });

      if (phone_field.val().indexOf("_") < 0) phone_field.data("valid_phone", true);

      $("#registria_form").on('blur change keyup', phone_field, function() {
        if(phone_field.data("mask")){
          var optional = phone_field.data("mask").indexOf("?");
          if (optional != -1 && phone_field.val().indexOf("_") >= optional) {
            phone_field.data("valid_phone", true);
          } else if (phone_field.val().indexOf("_") >= 0) {
            phone_field.data("valid_phone", false);
          } else {
            phone_field.data("valid_phone", true);
          }
        }
      });
    }
  }
  if ($(".mask-money").length) {
    $(".mask-money").on('input', function() {
      if(this.value.length > 0) {
        if (this.value.length === 1) { this.value = '0'+this.value; }
        this.value = parseFloat(this.value.replace(/[^\d]/g,'').replace(/(\d\d?)$/,'.$1')).toFixed(2);
      }
    });
    $(".mask-money").on('blur', function() {
      if($(".mask-money").val() <=0 ){ $(".mask-money").val('');}
    });
  }
  // Price paid validation
  $.validator.addMethod('strictPrice', function(value, element) {
    return $(element).data("valid_price");
  }, $.polyglot.t('validations.required'));

  if ($(":input[name*='registration[price_paid]']").length) {
    var price_field = $(":input[name*='registration[price_paid]']");
    var isRequired = price_field[0].required
    if ( _.get($.siteConfiguration,"registration_price_paid_field.display" )){
      price_field.data("required_price", isRequired);
      price_field.rules('add', {
        strictPrice: isRequired
      });
      $("#registria_form").on('blur change keyup', price_field, function() {
        if (price_field.val()) {
          price_field.data("valid_price", true);
        }
        else {
          price_field.data("valid_price", false);
        }
      });
    }
  }
});
$(function() {
  // Weber serial validation
  if($("#serial").length && $.siteConfiguration.site_name === "Weber" ){
    var bypass = $.siteConfiguration.registration_serial_number_field.bypass;
    var requiredAttempts = $.siteConfiguration.registration_serial_number_field.required_attempts;
    var attempts = 0;
    var subdomain = window.location.hostname.split(".")[0];
    var translate = function translate(text) {
      switch (text) {
        case 'invalid/not found serial number':
          return $.polyglot.t('validations.serial_number_invalid');
        case 'serial number already used':
          return $.polyglot.t('validations.serial_number_used');
        case 'serial number not valid for entered model':
          return $.polyglot.t('validations.serial_number_mismatch');
        case 'invalid purchase date specified':
          return $.polyglot.t('validations.purchase_date_invalid');
      }
    }

    $("#serial").rules("add",{
      "remote" :{
        param: {
          url: '/' + subdomain + '/validate_serial?',
          type: "POST",
          async: true,
          data:
          {
            serial_number: function(){
              return $("#serial").val();
            },
            product_id: function(){
              return $("#product").val();
            },
            purchase_date: function(){
              return $("#purchase_date").val();
            }
          },
          dataFilter: function(data) {
            data=JSON.parse(data);
            attempts++;
            if(data.success) {
              $("#model").val(data.product_name+' ('+data.model+')');
              $('#product').val(data.product_id);
              document.getElementById("model").style.textTransform = "capitalize";
              $("label[for='model'] .frauto-list > li.selected").removeClass('selected');
              $('label[for="serial"]').css("margin-bottom","0px");
              $('#registration_status_short_code').val("");
              $('#model').prop('readonly', true);
              return true;
            } else if (bypass && attempts > requiredAttempts) {
              $('label[for="serial"]').css("margin-bottom","0px");
              $('#registration_status_short_code').val("invalid_serial");
              return true;
            } else {
              if(data.message.indexOf("invalid purchase date specified") < 0 ) {
                $("#model").val('');
                $('#product').val('');
              } else {
                $("#serial").trigger('focus');
              }
              if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                $('label[for="serial"]').css("margin-bottom","70px");
              } else {
                $('label[for="serial"]').css("margin-bottom","40px");
              }
              var msg = translate(data.message);
              return "\"" + msg + "\"";
            }
          }
        }
      }
    });
    $('#serial').on('keyup',function(e){
      e.stopPropagation();
      $("#serial").removeClass("invalid");
      $('label[for="serial"].invalid').html("");
      $('label[for="serial"]').css("margin-bottom","0px");
    });
    $('#model').on('change blur', function () {
      $('#serial').removeData("previousValue");
    });
    $('#purchase_date').on('change', function () {
      $('#serial').removeData("previousValue");
    });
  }
});


$(document).ready(function() {
  if (!localStorage.bannerClosed) {
    $('.cookieinfo-banner').css('display', 'inherit');
  } else {
    $('.cookieinfo-banner').css('display', 'none');
  }
  $('.cookieinfo-accept').on('click', function() {
    $('.cookieinfo-banner').css('display', 'none');
    $('#cookie_policy_accepted').val(1);
    localStorage.bannerClosed = 'true'
  });
});
$(function() {
  if ($('.mask-date').length > 0) {
    $('.mask-date').each(function(){
      var p_date = $(this).attr('id');
      p_date = "#"+p_date;

      if(!$(p_date).prop('required')){
        $(p_date).rules('add', {
          futureDate: false
        });
      } else {
        $(p_date).rules('add', {
          futureDate: true,
          messages: {
            // futureDate correct here?
            futureDate: $.polyglot.t('validations.required')
          }
        });
      }
   });
    $('.mask-date').on('keyup blur', function() {
      var p_date = "#"+$(this).attr('id');
      if((isFutureDate($(p_date).val())) ){
        $(p_date).rules('add', {
          futureDate: true,
          messages: {
            futureDate: $.polyglot.t('validations.date_not_in_future')
          }
        });
      } else {
        if(!$(p_date).prop('required')){
          $(p_date).rules('add', {
            futureDate: false
          });
        } else {
          $(p_date).rules('add', {
            futureDate: true,
            messages: {
              // futureDate correct here?
              futureDate: $.polyglot.t('validations.required')
            }
          });
        }
      }
    });
  }

  /* Check given purchase date is future date or not */
  function isFutureDate(idate){
    var today = new Date().getTime(),
      idate = idate.split("/");
    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    //console.log("idate:"+idate);
    return (today - idate) < 0 ? true : false;
  }
});